var navbar = function () {
    var scroll = $(window).scrollTop();
    var pos = $('#main').offset().top - 127;
    if (scroll > pos) {
        $('.navbar').addClass('scrolled');
    } else {
        $('.navbar').removeClass('scrolled');
    }
}


$(document).ready(function () {
    navbar();

    $('.bg-light-gray .ce_text .inner').matchHeight();

    $('#newsletter input[type=text]').addClass('form-control');

});

$(window).resize(function () {
    navbar();
});

$(window).scroll(function () {
    navbar();
});